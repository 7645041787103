import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";
import { User } from "shared/datamodel/schemas";
import { flattenObjRecursive } from "shared/util/utils";

export function setUpAnalyticTools(user: User) {
  if (typeof window === "undefined") return;

  posthog.identify(user.id.toString(), prepareUserObjectForPosthog(user));
  posthog.group("account", `id::${user?.accountId || user.account?.id || ""}`, {});

  mixpanel.identify(user.id.toString());
  mixpanel.register(user);
  mixpanel.people.set(user);

  setFirstTimeUserInitialProperties(user);
  syncFeatureFlags();

  if (
    user.account?.id === "5" ||
    process.env.NEXT_ENV !== "production" ||
    (window as any)._DATADOG_SYNTHETICS_BROWSER !== undefined
  ) {
    mixpanel.opt_out_tracking(); //don't send data for monday account users or in dev or for datadog bots
  }
}

function getSafeUrlPathname(url: string) {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url;
  }
}

function setFirstTimeUserInitialProperties(user: User) {
  const storedPersonProps = posthog.get_property("$stored_person_properties");

  if (!storedPersonProps?.$initial_pathname) {
    const initialPersionInfo = posthog.get_property("$initial_person_info")?.u || window.location.hostname;
    const propertiesForFeatureFlags = {
      ...user,
      $initial_pathname: getSafeUrlPathname(initialPersionInfo),
    };

    posthog.setPersonPropertiesForFlags(propertiesForFeatureFlags);
  }
}

function syncFeatureFlags() {
  posthog.onFeatureFlags((_flags, variants) => {
    Object.entries(variants).forEach(([key, value]) => {
      if (key.includes("ab-test")) {
        mixpanel.register({ [key]: value });
        mixpanel.people.set(key, value);
      }
    });
  });
}

/**
 * Sets the marketing properties for the user in mixpanel/posthog
 */
function setupMarketingProps() {
  const utmParams = getUTMParams();

  if (Object.keys(utmParams).length === 0) return;

  // Transform the utm keys to initial_utm keys
  Object.keys(utmParams).forEach((key) => {
    const newKey = `initial_${key}`;
    utmParams[newKey] = utmParams[key];
    delete utmParams[key];
  });

  mixpanel.register_once(utmParams);
  mixpanel.people.set_once(utmParams);
  posthog.people.set_once(utmParams);
}

/**
 * This function filters certain properties and flattens the user object to sync to posthog, because the filters on posthog struggle with nested objects
 * @param user The user object to sync to posthog
 * @returns A flattened object that includes the user object and the planInfo and account objects
 */
function prepareUserObjectForPosthog(user: User) {
  const filteredUserObject = structuredClone(user);
  delete filteredUserObject.announcement;
  delete filteredUserObject.repsToken;
  delete filteredUserObject.serialNumber;
  delete filteredUserObject.planInfo?.enc_features;
  return flattenObjRecursive(filteredUserObject);
}

function getUTMParams() {
  if (typeof window === "undefined") return {};
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams: any = {};
  urlParams.forEach((value, key) => {
    if (key.includes("utm_")) {
      utmParams[key] = value;
    }
  });
  return utmParams;
}